import Navbar from '~/components/Navbar'
import Header from '~/components/Header'
import Main from '~/components/Main'
import Footer from '~/components/Footer'

const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Main />
      <Footer />
    </>
  )
}

export default Home

import classNames from 'classnames/bind'
import styles from '~/components/GlobalStyles/GlobalStyles.module.css'
import Recording from '~/assets/img/Recording.mp4'
import Feature from '~/assets/img/Feature.mp4'
import ChatFeature from '~/assets/img/ChatFeature.mp4'
import GooglePlayBadge from '~/assets/img/google-play-badge.svg'
import AppStoreBadge from '~/assets/img/app-store-badge.svg'

const cx = classNames.bind(styles)

const Main = () => {
  return (
    <>
      <aside className={cx('text-center', 'bg-gradient-primary-to-secondary')}>
        <div className='container px-5'>
          <div className='row gx-5 justify-content-center'>
            <div className='col-xl-8'>
              <div className={cx('h2', 'fs-1', 'text-white', 'mb-4')}>
                "Một giải pháp trực quan cho một số vấn đề phổ biến mà tất cả chúng ta đều gặp phải, gói gọn trong một
                ứng dụng!"
              </div>
            </div>
          </div>
        </div>
      </aside>

      <section id='features'>
        <div className='container px-5'>
          <div className='row gx-5 align-items-center'>
            <div className='col-lg-8 order-lg-1 mb-5 mb-lg-0'>
              <div className='container-fluid px-5'>
                <div className='row gx-5'>
                  <div className='col-md-6 mb-5'>
                    <div className='text-center'>
                      <i className={cx('bi', 'bi-pencil-fill', 'icon-feature', 'text-gradient', 'd-block', 'mb-3')}></i>
                      <h3 className={cx('font-alt')}>Viết nội dung</h3>
                      <p className={cx('text-muted', 'mb-0')}>
                        Giúp bạn viết bất kì thứ gì (Email, Đoạn văn, Câu chuyện ...)
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6 mb-5'>
                    <div className='text-center'>
                      <i className={cx('bi', 'bi-pencil-fill', 'icon-feature', 'text-gradient', 'd-block', 'mb-3')}></i>
                      <h3 className={cx('font-alt')}>Trả lời nội dung</h3>
                      <p className='text-muted mb-0'>
                        Luôn sẵn sàng hổ trợ bạn trả lời mọi thứ như email, tin nhắn, thư từ... !
                      </p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mb-5 mb-md-0'>
                    <div className='text-center'>
                      <i className={cx('bi', 'bi-stars', 'icon-feature', 'text-gradient', 'd-block', 'mb-3')}></i>
                      <h3 className={cx('font-alt')}>Tối ưu nội dung</h3>
                      <p className='text-muted mb-0'>
                        Tôi có thể cung cấp gợi ý, chỉnh sửa và phân tích nội dung để đảm bảo rằng nội dung của bạn là
                        súc tích, chính xác và hấp dẫn.!
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='text-center'>
                      <i
                        className={cx('bi', 'bi-blockquote-left', 'icon-feature', 'text-gradient', 'd-block', 'mb-3')}
                      ></i>
                      <h3 className={cx('font-alt')}>Chuyên nghiệp</h3>
                      <p className='text-muted mb-0'>
                        Cung cấp các ngữ cảnh, gợi ý, văn phong, kỹ thuật viết, giúp bài viết của bạn chuyên nghiệp hơn
                        bao giờ hết!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 order-lg-0'>
              <div className={cx('features-device-mockup')}>
                <svg className={cx('circle')} viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
                  <defs>
                    <linearGradient id='circleGradient' gradientTransform='rotate(45)'>
                      <stop className={cx('gradient-start-color')} offset='0%'></stop>
                      <stop className={cx('gradient-end-color')} offset='100%'></stop>
                    </linearGradient>
                  </defs>
                  <circle cx='50' cy='50' r='50'></circle>
                </svg>
                <svg
                  className={cx('shape-1', 'd-none', 'd-sm-block')}
                  viewBox='0 0 240.83 240.83'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    x='-32.54'
                    y='78.39'
                    width='305.92'
                    height='84.05'
                    rx='42.03'
                    transform='translate(120.42 -49.88) rotate(45)'
                  ></rect>
                  <rect
                    x='-32.54'
                    y='78.39'
                    width='305.92'
                    height='84.05'
                    rx='42.03'
                    transform='translate(-49.88 120.42) rotate(-45)'
                  ></rect>
                </svg>
                <svg
                  className={cx('shape-2', 'd-none', 'd-sm-block')}
                  viewBox='0 0 100 100'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='50' cy='50' r='50'></circle>
                </svg>
                <div className={cx('device-wrapper')}>
                  <div className={cx('device')} data-device='iPhoneX' data-orientation='portrait' data-color='black'>
                    <div className={cx('screen', 'bg-black')}>
                      <video
                        muted='muted'
                        autoPlay={true}
                        loop={true}
                        style={{ maxWidth: '100%', height: '100%', objectFit: 'contain' }}
                      >
                        <source src={Recording} type='video/mp4' />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='features' className='bg-light'>
        <div className='container px-5'>
          <div className='row gx-5 align-items-center'>
            <div className='col-lg-4 order-lg-1'>
              <div className={cx('features-device-mockup')}>
                <svg className={cx('circle')} viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
                  <defs>
                    <linearGradient id='circleGradient' gradientTransform='rotate(45)'>
                      <stop className={cx('gradient-start-color')} offset='0%'></stop>
                      <stop className={cx('gradient-end-color')} offset='100%'></stop>
                    </linearGradient>
                  </defs>
                  <circle cx='50' cy='50' r='50'></circle>
                </svg>
                <svg
                  className={cx('shape-1', 'd-none', 'd-sm-block')}
                  viewBox='0 0 240.83 240.83'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    x='-32.54'
                    y='78.39'
                    width='305.92'
                    height='84.05'
                    rx='42.03'
                    transform='translate(120.42 -49.88) rotate(45)'
                  ></rect>
                  <rect
                    x='-32.54'
                    y='78.39'
                    width='305.92'
                    height='84.05'
                    rx='42.03'
                    transform='translate(-49.88 120.42) rotate(-45)'
                  ></rect>
                </svg>
                <svg className='shape-2 d-none d-sm-block' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
                  <circle cx='50' cy='50' r='50'></circle>
                </svg>
                <div className={cx('device-wrapper')}>
                  <div className={cx('device')} data-device='iPhoneX' data-orientation='portrait' data-color='black'>
                    <div className={cx('screen', 'bg-black')}>
                      <video muted='muted' autoPlay={true} loop={true} style={{ maxWidth: '100%', height: '100%' }}>
                        <source src={Feature} type='video/mp4' />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8 order-lg-0 mb-5 mb-lg-0'>
              <div className='container-fluid px-5'>
                <div className='row gx-5'>
                  <div className='col-md-6 mb-5'>
                    <div className='text-center'>
                      <i
                        className={cx('bi', 'bi-graph-up-arrow', 'icon-feature', 'text-gradient', 'd-block', 'mb-3')}
                      ></i>
                      <h3 className={cx('font-alt')}>Hổ trợ công việc Kinh Doanh</h3>
                      <p className='text-muted mb-0'>
                        Nghiên cứu đối thủ cạnh tranh, Viết kế hoạch kinh doanh, tóm tắt cuộc họp ...
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6 mb-5'>
                    <div className='text-center'>
                      <i
                        className={cx(
                          'bi',
                          'bi-file-earmark-person',
                          'icon-feature',
                          'text-gradient',
                          'd-block',
                          'mb-3'
                        )}
                      ></i>
                      <h3 className={cx('font-alt')}>Phỏng vấn - Nhân sự </h3>
                      <p className={cx('text-muted', 'mb-0')}>
                        Hổ trợ trả lời phỏng vấn, đặt câu hỏi phỏng vấn, viết CV...
                      </p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mb-5 mb-md-0'>
                    <div className='text-center'>
                      <i className={cx('bi', 'bi-facebook', 'icon-feature', 'text-gradient', 'd-block', 'mb-3')}></i>
                      <h3 className={cx('font-alt')}>Tiếp thị</h3>
                      <p className={cx('text-muted', 'mb-0')}>
                        Viết bài Facebook, viết Blog, chào hàng, khuyến mãi....
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='text-center'>
                      <i
                        className={cx('bi', 'bi-airplane-fill', 'icon-feature', 'text-gradient', 'd-block', 'mb-3')}
                      ></i>
                      <h3 className={cx('font-alt')}>Nội trợ, Du lịch, và nhiều hơn thế</h3>
                      <p className={cx('text-muted', 'mb-0')}>
                        Cung cấp các công thức nấu ăn, gợi ý địa điểm du lịch tối ưu hóa thời gian, chi phí của bạn!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='bg'>
        <div className='container px-5'>
          <div className='row gx-5 align-items-center justify-content-center justify-content-lg-between'>
            <div className='col-sm-8 col-md-6'>
              <div className='px-5 px-sm-0'>
                <video muted='muted' autoPlay={true} loop={true} className='img-fluid rounded-circle'>
                  <source src={ChatFeature} type='video/mp4' />
                </video>
              </div>
            </div>
            <div className='col-12 col-lg-5'>
              <h2 className={cx('display-4', 'lh-1', 'mb-4')}>Trò chuyện như một chuyên gia</h2>
              <p className={cx('lead', 'fw-normal', 'text-muted', 'mb-5', 'mb-lg-0')}>
                Bằng tính năng{' '}
                <span className={cx('highlight-container')}>
                  <span className={cx('highlight')}>CHAT</span>
                </span>{' '}
                hoặc{' '}
                <span className={cx('highlight-container')}>
                  <span className={cx('highlight')}>VOICE CHAT</span>
                </span>{' '}
                bạn có thể khám phá khả năng tuyệt vời của tôi như một chuyên gia trả lời mọi câu hỏi! Tôi là nguồn
                thông tin đáng tin cậy, sẵn sàng giải đáp mọi thắc mắc và thách thức trí tuệ của bạn. Từ lĩnh vực khoa
                học, lịch sử, nghệ thuật đến công nghệ và thể thao, không có giới hạn đối với sự hiểu biết và sự sáng
                tạo của tôi. Đặt ra câu hỏi, và tôi sẽ mang đến những câu trả lời chi tiết, hấp dẫn và đầy thú vị. Hãy
                khám phá sự tò mò và mở rộng kiến thức với tôi - đối tác trí tuệ đắc lực của bạn!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={cx('cta')}>
        <div className={cx('cta-content')}>
          <div className='container px-5'>
            <h2 className='text-white display-1 lh-1 mb-4'>
              Ngừng chần chừ
              <br />
              Dùng thử ngay!
            </h2>
            <a className='btn btn-outline-light py-3 px-4 rounded-pill' href='#download'>
              Tải xuống miễn phí
            </a>
          </div>
        </div>
      </section>

      <section className={cx('bg-gradient-primary-to-secondary')} id='download'>
        <div className='container px-5'>
          <h2 className={cx('text-center', 'text-white', 'font-alt', 'mb-4')}>Tải ứng dụng ngay bây giờ!</h2>
          <div className='d-flex flex-column flex-lg-row align-items-center justify-content-center'>
            <a className='me-lg-3 mb-4 mb-lg-0' href='#!'>
              <img className={cx('app-badge')} src={GooglePlayBadge} alt='Google play' />
            </a>
            <a href='#!'>
              <img className={cx('app-badge')} src={AppStoreBadge} alt='App store' />
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main

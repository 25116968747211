import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import styles from '~/components/GlobalStyles/GlobalStyles.module.css'

const cx = classNames.bind(styles)

const Navbar = () => {
  return (
    <nav className={cx('navbar', 'navbar-expand-lg', 'navbar-light', 'fixed-top', 'shadow-sm')} id={cx('mainNav')}>
      <div className={cx('container')}>
        <Link to='/' className={`${cx('navbar-brand', 'fw-bold')}`} href='#page-top'>
          Trợ lý GPT
        </Link>
        <button
          className={cx('navbar-toggler')}
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarResponsive'
          aria-controls='navbarResponsive'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          Menu
          <i className={cx('bi-list')}></i>
        </button>
        <div className={`collapse ${cx('navbar-collapse')}`} id='navbarResponsive'>
          <ul className={`${cx('navbar-nav', 'ms-auto', 'me-4', 'my-3', 'my-lg-0')}`}>
            <li className={cx('nav-item')}>
              <Link className={`${cx('nav-link', 'me-lg-3')}`}>Tính năng</Link>
            </li>
            <li className={cx('nav-item')}>
              <Link className={`${cx('nav-link', 'me-lg-3')}`}>Tải xuống</Link>
            </li>
            <li className={cx('nav-item')}>
              <Link to='/terms-of-use' className={`${cx('nav-link', 'me-lg-3')}`}>
                Điều khoản sử dụng
              </Link>
            </li>
            <li className={cx('nav-item')}>
              <Link to='/privacy-policy' className={`${cx('nav-link', 'me-lg-3')}`}>
                Chính sách bảo mật
              </Link>
            </li>
          </ul>
          <button
            className={`${cx('btn', 'btn-primary', 'rounded-pill', 'px-3', 'mb-2', 'mb-lg-0')}`}
            data-bs-toggle='modal'
            data-bs-target='#feedbackModal'
          >
            <span className='d-flex align-items-center'>
              <i className='bi-chat-text-fill me-2'></i>
              <span className='small'>Gửi phản hồi</span>
            </span>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

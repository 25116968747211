import classNames from 'classnames/bind'
import styles from '~/components/GlobalStyles/GlobalStyles.module.css'

const cx = classNames.bind(styles)

const Footer = () => {
  return (
    <footer className='bg-black text-center py-5'>
      <div className='container px-5'>
        <div className={cx('text-white-50', 'small')}>
          <div className={cx('mb-2')}>&copy; 2023. All Rights Reserved.</div>
          <a href='#!'>Privacy</a>
          <span className={cx('mx-1')}>&middot;</span>
          <a href='#!'>Terms</a>
          <span className={cx('mx-1')}>&middot;</span>
          <a href='#!'>FAQ</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer

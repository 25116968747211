import classNames from 'classnames/bind'
import styles from '~/components/GlobalStyles/GlobalStyles.module.css'
import GooglePlayBadge from '~/assets/img/google-play-badge.svg'
import AppStoreBadge from '~/assets/img/app-store-badge.svg'
import MK from '~/assets/img/mk.png'
import Chat from '~/assets/img/chat.png'
import Listening from '~/assets/img/listening.png'

const cx = classNames.bind(styles)

const Header = () => {
  return (
    <header className={cx('masthead')}>
      <div className='container px-5'>
        <div className='row gx-5 align-items-center'>
          <div className='col-lg-6'>
            <div className='mb-5 mb-lg-0 text-center text-lg-start'>
              <h1 className='display-1 lh-1 mb-3'>
                TRỢ LÝ <span style={{ color: '#6172F3' }}>GPT</span>
              </h1>
              <p className={`${cx('lead', 'fw-normal', 'text-muted', 'mb-5')}`}>
                Trợ thủ đắt lực cho công việc của bạn. Cài đặt ngay!
              </p>
              <div className='d-flex flex-column flex-lg-row align-items-center'>
                <a className='me-lg-3 mb-4 mb-lg-0' href='#!'>
                  <img className={cx('app-badge')} src={GooglePlayBadge} alt='Google play' />
                </a>
                <a href='#!'>
                  <img className={cx('app-badge')} src={AppStoreBadge} alt='App store' />
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className={cx('masthead-device-mockup')}>
              <svg className={cx('circle')} viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
                <defs>
                  <linearGradient id='circleGradient' gradientTransform='rotate(45)'>
                    <stop className={cx('gradient-start-color')} offset='0%'></stop>
                    <stop className={cx('gradient-end-color')} offset='100%'></stop>
                  </linearGradient>
                </defs>
                <circle cx='50' cy='50' r='50'></circle>
              </svg>
              <svg
                className={cx('shape-1', 'd-none', 'd-sm-block')}
                viewBox='0 0 240.83 240.83'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  x='-32.54'
                  y='78.39'
                  width='305.92'
                  height='84.05'
                  rx='42.03'
                  transform='translate(120.42 -49.88) rotate(45)'
                ></rect>
                <rect
                  x='-32.54'
                  y='78.39'
                  width='305.92'
                  height='84.05'
                  rx='42.03'
                  transform='translate(-49.88 120.42) rotate(-45)'
                ></rect>
              </svg>
              <svg
                className={cx('shape-1', 'd-none', 'd-sm-block')}
                viewBox='0 0 100 100'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='50' cy='50' r='50'></circle>
              </svg>
              <div className={cx('device-wrapper')} style={{ marginRight: '-25px' }}>
                <div className={cx('device')} data-device='iPhoneX' data-orientation='portrait' data-color='black'>
                  <div className={cx('screen', 'bg-black')}>
                    <img src={MK} alt='' style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
              </div>
              <div className={cx('device-wrapper')} style={{ zIndex: 1 }}>
                <div className={cx('device')} data-device='iPhoneX' data-orientation='portrait' data-color='black'>
                  <div className={cx('screen', 'bg-black')}>
                    <img src={Chat} alt='' style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
              </div>
              <div className={cx('device-wrapper')} style={{ marginLeft: '-25px' }}>
                <div className={cx('device')} data-device='iPhoneX' data-orientation='portrait' data-color='black'>
                  <div className={cx('screen', 'bg-black')}>
                    <img src={Listening} alt='' style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

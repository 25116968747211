import classNames from 'classnames/bind'
import styles from '~/components/GlobalStyles/GlobalStyles.module.css'
import Navbar from '~/components/Navbar'
import Footer from '~/components/Footer'

const cx = classNames.bind(styles)

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className={cx('container')}>
        <div>
          <br />
          <br />
          <br />
          <br />
          <p style={{ textAlign: 'center' }}>
            <strong style={{ fontSize: '20px' }}>CH&Iacute;NH S&Aacute;CH BẢO MẬT</strong>
          </p>
          <p>
            Ch&uacute;ng t&ocirc;i đặt một sự quan trọng cao đối với bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n của
            bạn khi sử dụng ứng dụng của ch&uacute;ng t&ocirc;i. Ch&iacute;nh s&aacute;ch n&agrave;y m&ocirc; tả
            c&aacute;ch ch&uacute;ng t&ocirc;i thu thập, sử dụng v&agrave; bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n
            của bạn. Vui l&ograve;ng đọc kỹ ch&iacute;nh s&aacute;ch n&agrave;y trước khi cung cấp th&ocirc;ng tin
            c&aacute; nh&acirc;n của bạn. Bằng c&aacute;ch truy cập hoặc sử dụng ứng dụng, bạn đồng &yacute; với việc
            thu thập, sử dụng v&agrave; bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của bạn theo c&aacute;c điều khoản
            của ch&iacute;nh s&aacute;ch n&agrave;y.
          </p>
          <p>
            <strong style={{ fontSize: '18px' }}>1. &nbsp; &nbsp;Th&ocirc;ng tin c&aacute; nh&acirc;n thu thập</strong>
          </p>
          <p>
            Khi bạn sử dụng ứng dụng, ch&uacute;ng t&ocirc;i c&oacute; thể thu thập c&aacute;c loại th&ocirc;ng tin
            c&aacute; nh&acirc;n sau đ&acirc;y:
          </p>
          <ul>
            <li>
              Th&ocirc;ng tin c&aacute; nh&acirc;n cung cấp tự nguyện: Bao gồm t&ecirc;n, địa chỉ email, số điện thoại
              v&agrave; c&aacute;c th&ocirc;ng tin kh&aacute;c m&agrave; bạn cung cấp khi sử dụng ứng dụng.
            </li>
            <li>
              Th&ocirc;ng tin kh&ocirc;ng c&aacute; nh&acirc;n: Bao gồm th&ocirc;ng tin về thiết bị của bạn, như loại
              thiết bị, phi&ecirc;n bản hệ điều h&agrave;nh, địa chỉ IP, tr&igrave;nh duyệt v&agrave; th&ocirc;ng tin về
              hoạt động sử dụng ứng dụng.
            </li>
          </ul>
          <p>
            <strong style={{ fontSize: '18px' }}>
              2.&nbsp; &nbsp; Mục đ&iacute;ch sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n
            </strong>
          </p>
          <p>
            Ch&uacute;ng t&ocirc;i sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n của bạn cho c&aacute;c mục đ&iacute;ch
            sau:
          </p>
          <ul>
            <li>
              Cung cấp v&agrave; duy tr&igrave; ứng dụng: Bao gồm x&aacute;c nhận v&agrave; xử l&yacute; c&aacute;c giao
              dịch mua h&agrave;ng, cung cấp dịch vụ hỗ trợ v&agrave; cải thiện trải nghiệm người d&ugrave;ng.
            </li>
            <li>
              Gửi th&ocirc;ng tin li&ecirc;n quan: Bao gồm th&ocirc;ng tin về c&aacute;c cập nhật, th&ocirc;ng
              b&aacute;o, khuyến m&atilde;i hoặc tin tức li&ecirc;n quan đến ứng dụng v&agrave; c&aacute;c sản phẩm hoặc
              dịch vụ tương tự m&agrave; ch&uacute;ng t&ocirc;i cung cấp.
            </li>
            <li>
              Ph&acirc;n t&iacute;ch v&agrave; cải thiện: Sử dụng dữ liệu để ph&acirc;n t&iacute;ch v&agrave; cải thiện
              hiệu suất, t&iacute;nh năng v&agrave; nội dung của ứng dụng.
            </li>
          </ul>
          <p>
            <strong style={{ fontSize: '18px' }}>3.&nbsp; &nbsp; Bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n</strong>
          </p>
          <ul>
            <li>
              Ch&uacute;ng t&ocirc;i cam kết bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của bạn v&agrave; &aacute;p
              dụng c&aacute;c biện ph&aacute;p bảo mật ph&ugrave; hợp để ngăn chặn truy cập tr&aacute;i ph&eacute;p, sử
              dụng, tiết lộ hoặc hủy hoại th&ocirc;ng tin c&aacute; nh&acirc;n của bạn.
            </li>
            <li>
              Tuy nhi&ecirc;n, việc truyền th&ocirc;ng tin qua internet kh&ocirc;ng ho&agrave;n to&agrave;n an
              to&agrave;n. Ch&uacute;ng t&ocirc;i kh&ocirc;ng thể đảm bảo t&iacute;nh bảo mật ho&agrave;n to&agrave;n
              của th&ocirc;ng tin c&aacute; nh&acirc;n khi truyền từ thiết bị của bạn đến ứng dụng. Ch&uacute;ng
              t&ocirc;i cũng &aacute;p dụng c&aacute;c biện ph&aacute;p bảo mật vật l&yacute;, điện tử v&agrave; quản
              l&yacute; để bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n khi n&oacute; được lưu trữ trong hệ thống của
              ch&uacute;ng t&ocirc;i.
            </li>
          </ul>
          <p>
            <strong style={{ fontSize: '18px' }}>4.&nbsp; &nbsp; Chia sẻ th&ocirc;ng tin c&aacute; nh&acirc;n</strong>
          </p>
          <ul>
            <li>
              Ch&uacute;ng t&ocirc;i kh&ocirc;ng chia sẻ th&ocirc;ng tin c&aacute; nh&acirc;n của bạn với bất kỳ
              b&ecirc;n thứ ba n&agrave;o trừ khi c&oacute; sự đồng &yacute; của bạn hoặc khi ch&uacute;ng t&ocirc;i cần
              phải tu&acirc;n thủ theo c&aacute;c y&ecirc;u cầu ph&aacute;p l&yacute; hoặc để bảo vệ quyền, t&agrave;i
              sản v&agrave; an ninh của ch&uacute;ng t&ocirc;i v&agrave; người d&ugrave;ng kh&aacute;c. Tuy nhi&ecirc;n,
              ch&uacute;ng t&ocirc;i c&oacute; thể chia sẻ th&ocirc;ng tin kh&ocirc;ng c&aacute; nh&acirc;n v&agrave;
              th&ocirc;ng tin đ&atilde; được ẩn danh m&agrave; kh&ocirc;ng tiết lộ danh t&iacute;nh c&aacute; nh&acirc;n
              của bạn.
            </li>
          </ul>
          <p>
            <strong style={{ fontSize: '18px' }}>5.&nbsp; &nbsp; Dữ liệu trẻ em</strong>
          </p>
          <ul>
            <li>
              Ứng dụng của ch&uacute;ng t&ocirc;i kh&ocirc;ng d&agrave;nh cho trẻ em dưới 13 tuổi. Ch&uacute;ng
              t&ocirc;i kh&ocirc;ng c&oacute; &yacute; định thu thập th&ocirc;ng tin c&aacute; nh&acirc;n từ trẻ em dưới
              tuổi n&agrave;y. Nếu bạn l&agrave; phụ huynh hoặc người gi&aacute;m hộ v&agrave; tin rằng trẻ em của bạn
              đ&atilde; cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n cho ch&uacute;ng t&ocirc;i, vui l&ograve;ng
              li&ecirc;n hệ với ch&uacute;ng t&ocirc;i để ch&uacute;ng t&ocirc;i c&oacute; thể x&oacute;a th&ocirc;ng
              tin đ&oacute;.
            </li>
          </ul>
          <p>
            <strong style={{ fontSize: '18px' }}>6.&nbsp; &nbsp; Li&ecirc;n kết đến b&ecirc;n thứ ba</strong>
          </p>
          <ul>
            <li>
              Ứng dụng c&oacute; thể chứa li&ecirc;n kết đến c&aacute;c trang web hoặc dịch vụ của b&ecirc;n thứ ba.
              Ch&uacute;ng t&ocirc;i kh&ocirc;ng chịu tr&aacute;ch nhiệm về nội dung hoặc thực h&agrave;nh bảo mật của
              c&aacute;c trang web hoặc dịch vụ n&agrave;y. Ch&uacute;ng t&ocirc;i khuyến nghị bạn kiểm tra ch&iacute;nh
              s&aacute;ch bảo mật của b&ecirc;n thứ ba trước khi cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n cho họ.
            </li>
          </ul>
          <p>
            <strong style={{ fontSize: '18px' }}>7.&nbsp; &nbsp; Thay đổi ch&iacute;nh s&aacute;ch</strong>
          </p>
          <ul>
            <li>
              Ch&uacute;ng t&ocirc;i c&oacute; quyền sửa đổi hoặc cập nhật ch&iacute;nh s&aacute;ch bảo mật n&agrave;y
              theo quyết định của ch&uacute;ng t&ocirc;i. Bất kỳ thay đổi n&agrave;o sẽ được th&ocirc;ng b&aacute;o
              tr&ecirc;n ứng dụng hoặc qua c&aacute;c phương tiện th&iacute;ch hợp kh&aacute;c. Việc tiếp tục sử dụng
              ứng dụng sau khi c&oacute; sự thay đổi n&agrave;y đồng nghĩa với việc bạn chấp nhận c&aacute;c thay đổi
              đ&oacute;.
            </li>
          </ul>
          <p>
            <strong style={{ fontSize: '18px' }}>8.&nbsp; &nbsp; Quyền của người d&ugrave;ng</strong>
          </p>
          <ul>
            <li>
              Bạn c&oacute; quyền truy cập, chỉnh sửa hoặc x&oacute;a th&ocirc;ng tin c&aacute; nh&acirc;n của
              m&igrave;nh trong ứng dụng. Bạn cũng c&oacute; quyền y&ecirc;u cầu ch&uacute;ng t&ocirc;i ngừng sử dụng
              hoặc x&oacute;a ho&agrave;n to&agrave;n th&ocirc;ng tin c&aacute; nh&acirc;n của bạn khỏi hệ thống của
              ch&uacute;ng t&ocirc;i, trừ khi ch&uacute;ng t&ocirc;i cần lưu giữ th&ocirc;ng tin đ&oacute; cho
              c&aacute;c mục đ&iacute;ch ph&aacute;p l&yacute; hoặc kinh doanh hợp lệ.
              <br />
              Nếu bạn muốn thực hiện bất kỳ y&ecirc;u cầu n&agrave;o li&ecirc;n quan đến th&ocirc;ng tin c&aacute;
              nh&acirc;n của m&igrave;nh hoặc c&oacute; bất kỳ c&acirc;u hỏi hoặc phản hồi n&agrave;o về ch&iacute;nh
              s&aacute;ch bảo mật, vui l&ograve;ng li&ecirc;n hệ với ch&uacute;ng t&ocirc;i theo th&ocirc;ng tin
              li&ecirc;n hệ được cung cấp trong ứng dụng.
            </li>
          </ul>
          <p>
            Ch&uacute;ng t&ocirc;i cam kết tu&acirc;n thủ c&aacute;c nguy&ecirc;n tắc v&agrave; quy định bảo vệ quyền
            ri&ecirc;ng tư v&agrave; th&ocirc;ng tin c&aacute; nh&acirc;n của bạn theo quy định của ph&aacute;p luật
            hiện h&agrave;nh. Ch&uacute;ng t&ocirc;i sẽ đảm bảo rằng th&ocirc;ng tin c&aacute; nh&acirc;n của bạn được
            bảo vệ v&agrave; xử l&yacute; một c&aacute;ch an to&agrave;n v&agrave; đ&uacute;ng đắn.
            <br />
            Xin lưu &yacute; rằng ch&iacute;nh s&aacute;ch bảo mật n&agrave;y chỉ &aacute;p dụng cho việc thu thập, sử
            dụng v&agrave; bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n trong ứng dụng của ch&uacute;ng t&ocirc;i. Khi
            bạn chuyển tới c&aacute;c trang web hoặc dịch vụ b&ecirc;n thứ ba, ch&uacute;ng t&ocirc;i khuyến nghị bạn
            đọc kỹ ch&iacute;nh s&aacute;ch bảo mật của họ trước khi cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n.
            <br />
            Cảm ơn bạn đ&atilde; tin tưởng v&agrave; sử dụng ứng dụng của ch&uacute;ng t&ocirc;i. Ch&uacute;ng t&ocirc;i
            cam kết cung cấp một trải nghiệm an to&agrave;n v&agrave; bảo mật cho bạn.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy

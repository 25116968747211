import classNames from 'classnames/bind'
import styles from '~/components/GlobalStyles/GlobalStyles.module.css'
import Navbar from '~/components/Navbar'
import Footer from '~/components/Footer'

const cx = classNames.bind(styles)

const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <div className={cx('container')}>
        <div>
          <br />
          <br />
          <br />
          <br />
          <p style={{ textAlign: 'center' }}>
            <strong>
              <span style={{ fontSize: '20px' }}>ĐIỀU KHOẢN SỬ DỤNG</span>
            </strong>
          </p>
          <p>
            Vui l&ograve;ng đọc kỹ c&aacute;c điều khoản v&agrave; điều kiện sau đ&acirc;y trước khi sử dụng ứng dụng
            của ch&uacute;ng t&ocirc;i. Bằng c&aacute;ch truy cập hoặc sử dụng ứng dụng, bạn đồng &yacute; tu&acirc;n
            thủ c&aacute;c điều khoản v&agrave; điều kiện n&agrave;y. Nếu bạn kh&ocirc;ng đồng &yacute; với bất kỳ điều
            khoản n&agrave;o, vui l&ograve;ng kh&ocirc;ng tiếp tục sử dụng ứng dụng.
          </p>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>1. &nbsp; &nbsp;Chấp nhận điều khoản</span>
            </strong>
          </p>
          <ul>
            <li>
              Bằng c&aacute;ch sử dụng ứng dụng, bạn x&aacute;c nhận rằng bạn đ&atilde; đọc, hiểu v&agrave; đồng
              &yacute; với c&aacute;c điều khoản v&agrave; điều kiện n&agrave;y.
            </li>
            <li>
              Bạn đồng &yacute; tu&acirc;n thủ tất cả c&aacute;c quy định ph&aacute;p luật v&agrave; quyền sở hữu
              tr&iacute; tuệ li&ecirc;n quan khi sử dụng ứng dụng.
            </li>
          </ul>
          <p>
            <span style={{ fontSize: '18px' }}>
              <strong>2. &nbsp; &nbsp;Quyền sở hữu tr&iacute; tuệ</strong>
            </span>
          </p>
          <ul>
            <li>
              Tất cả quyền sở hữu tr&iacute; tuệ, bao gồm bản quyền v&agrave; quyền sở hữu c&ocirc;ng nghiệp kh&aacute;c
              li&ecirc;n quan đến ứng dụng, thuộc về ch&uacute;ng t&ocirc;i hoặc c&aacute;c b&ecirc;n thứ ba tương ứng.
            </li>
            <li>
              Bạn kh&ocirc;ng được sao ch&eacute;p, sửa đổi, ph&acirc;n phối hoặc sử dụng bất kỳ th&agrave;nh phần
              n&agrave;o của ứng dụng m&agrave; kh&ocirc;ng c&oacute; sự cho ph&eacute;p trước bằng văn bản từ
              ch&uacute;ng t&ocirc;i.
            </li>
          </ul>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>3. &nbsp; &nbsp;Mua h&agrave;ng</span>
            </strong>
          </p>
          <ul>
            <li>
              Ứng dụng của ch&uacute;ng t&ocirc;i cung cấp chức năng mua h&agrave;ng qua Apple Store v&agrave; Chplay.
            </li>
            <li>
              Khi bạn sử dụng chức năng mua h&agrave;ng, bạn phải tu&acirc;n thủ c&aacute;c quy định v&agrave;
              ch&iacute;nh s&aacute;ch của Apple Store hoặc Chplay tương ứng.
            </li>
            <li>
              Ch&uacute;ng t&ocirc;i kh&ocirc;ng chịu tr&aacute;ch nhiệm về bất kỳ vấn đề n&agrave;o ph&aacute;t sinh từ
              qu&aacute; tr&igrave;nh mua h&agrave;ng, bao gồm nhưng kh&ocirc;ng giới hạn ở việc thanh to&aacute;n, giao
              h&agrave;ng v&agrave; bảo h&agrave;nh. Mọi tranh chấp hoặc khiếu nại li&ecirc;n quan đến việc mua
              h&agrave;ng sẽ được giải quyết giữa bạn v&agrave; Apple Store hoặc Chplay.
            </li>
          </ul>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>4. &nbsp; &nbsp;Tr&aacute;ch nhiệm v&agrave; giới hạn</span>
            </strong>
          </p>
          <ul>
            <li>
              Ch&uacute;ng t&ocirc;i cung cấp ứng dụng dưới dạng &quot;như đ&atilde; c&oacute;&quot; v&agrave;
              kh&ocirc;ng chịu bất kỳ tr&aacute;ch nhiệm n&agrave;o đối với việc sử dụng ứng dụng.
            </li>
            <li>
              Ch&uacute;ng t&ocirc;i kh&ocirc;ng chịu tr&aacute;ch nhiệm cho bất kỳ thiệt hại trực tiếp, gi&aacute;n
              tiếp, ngẫu nhi&ecirc;n hoặc bất kỳ thiệt hại ph&aacute;t sinh từ việc sử dụng ứng dụng, bao gồm nhưng
              kh&ocirc;ng giới hạn ở việc mất dữ liệu, gi&aacute;n đoạn hoạt động, thiệt hại do virus m&aacute;y
              t&iacute;nh hoặc c&aacute;c vấn đề kỹ thuật kh&aacute;c.
            </li>
            <li>
              Ch&uacute;ng t&ocirc;i kh&ocirc;ng đảm bảo rằng ứng dụng sẽ hoạt động kh&ocirc;ng gi&aacute;n đoạn hoặc
              kh&ocirc;ng c&oacute; lỗi. Ch&uacute;ng t&ocirc;i kh&ocirc;ng chịu tr&aacute;ch nhiệm đối với bất kỳ hỏng
              h&oacute;c, lỗi hoặc sự gi&aacute;n đoạn n&agrave;o trong việc cung cấp ứng dụng.
            </li>
          </ul>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>5. &nbsp; &nbsp;Bảo mật</span>
            </strong>
          </p>
          <ul>
            <li>
              Ch&uacute;ng t&ocirc;i cam kết bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của bạn theo ch&iacute;nh
              s&aacute;ch bảo mật ri&ecirc;ng tư. Tuy nhi&ecirc;n, việc truyền th&ocirc;ng tin qua internet kh&ocirc;ng
              ho&agrave;n to&agrave;n an to&agrave;n v&agrave; ch&uacute;ng t&ocirc;i kh&ocirc;ng đảm bảo t&iacute;nh
              bảo mật ho&agrave;n to&agrave;n của th&ocirc;ng tin bạn cung cấp.
            </li>
          </ul>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>6. &nbsp; &nbsp;Sửa đổi v&agrave; chấm dứt</span>
            </strong>
          </p>
          <ul>
            <li>
              Ch&uacute;ng t&ocirc;i c&oacute; quyền sửa đổi hoặc chấm dứt c&aacute;c điều khoản v&agrave; điều kiện
              n&agrave;y m&agrave; kh&ocirc;ng cần th&ocirc;ng b&aacute;o trước.
            </li>
            <li>
              Bạn c&oacute; thể chấm dứt việc sử dụng ứng dụng bất cứ l&uacute;c n&agrave;o bằng c&aacute;ch x&oacute;a
              ứng dụng khỏi thiết bị của bạn.
            </li>
          </ul>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>7. &nbsp; &nbsp;Luật &aacute;p dụng</span>
            </strong>
          </p>
          <ul>
            <li>
              C&aacute;c điều khoản v&agrave; điều kiện n&agrave;y sẽ được điều chỉnh v&agrave; diễn giải theo luật
              ph&aacute;p của quốc gia nơi bạn sử dụng ứng dụng.
            </li>
          </ul>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>8. &nbsp; &nbsp;Những hạn chế</span>
            </strong>
          </p>
          <ul>
            <li>Đ&ocirc;i khi c&oacute; thể tạo ra th&ocirc;ng tin kh&ocirc;ng ch&iacute;nh x&aacute;c</li>
            <li>Đ&ocirc;i khi c&oacute; thể tạo ra c&aacute;c hướng dẫn c&oacute; hại hoặc nội dung sai lệch</li>
            <li>Kiến thức hạn chế về thế giới v&agrave; c&aacute;c sự kiện sau năm 2021</li>
          </ul>
          <p>
            <strong>
              <span style={{ fontSize: '18px' }}>9. &nbsp; &nbsp;Khả năng</span>
            </strong>
          </p>
          <ul>
            <li>
              Ghi nhớ những g&igrave; người d&ugrave;ng đ&atilde; n&oacute;i trước đ&oacute; trong cuộc tr&ograve;
              chuyện
            </li>
            <li>Cho ph&eacute;p người d&ugrave;ng cung cấp c&aacute;c chỉnh sửa tiếp theo</li>
            <li>
              Được đ&agrave;o tạo để từ chối c&aacute;c y&ecirc;u cầu kh&ocirc;ng ph&ugrave; hợp, v&iacute; dụ
              c&aacute;c chủ đề tr&aacute;i với quy định ph&aacute;p luật, c&aacute;c chủ đề c&oacute; nội dung 18+,
              c&aacute;c chủ đề về ch&iacute;nh trị, t&ocirc;n gi&aacute;o ...
            </li>
          </ul>
          <p>
            Nếu bạn c&oacute; bất kỳ c&acirc;u hỏi hoặc khiếu nại n&agrave;o về c&aacute;c điều khoản v&agrave; điều
            kiện n&agrave;y, vui l&ograve;ng li&ecirc;n hệ với ch&uacute;ng t&ocirc;i qua th&ocirc;ng tin li&ecirc;n lạc
            được cung cấp trong ứng dụng.
          </p>
          <p>
            Xin vui l&ograve;ng lưu &yacute; rằng c&aacute;c điều khoản v&agrave; điều kiện n&agrave;y c&oacute; thể
            được điều chỉnh hoặc bổ sung theo quyết định của ch&uacute;ng t&ocirc;i m&agrave; kh&ocirc;ng cần
            th&ocirc;ng b&aacute;o trước.
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsOfUse

import { Route, Routes } from 'react-router-dom'
import Home from '~/pages/Home/Home'
import PrivacyPolicy from '~/pages/PrivacyPolicy'
import TermsOfUse from '~/pages/TermsOfUse'

function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/terms-of-use' element={<TermsOfUse />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
    </Routes>
  )
}

export default AppRoutes
